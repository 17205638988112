import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (
   <Component {...props} />
  );
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    // Main Routes
    {
      path: '*',
      element: <Claim />
    }
  ]);
}
const Claim = Loadable(lazy(() => import('../pages/Claim')));

